import React from "react";
import ReactMarkdown from "react-markdown";

import { Button, Cell, Grid, Link, Section } from "@Components/blocks";
import { ContactSectionProps } from "@Models/section";

const ContactSection = ({ description, button }: ContactSectionProps) => {
  return (
    <Section align="left" background="blue">
      <Grid columns={"1fr 0.25fr 0.5fr"} columnsSm={1}>
        <Cell middle>
          <ReactMarkdown>{description}</ReactMarkdown>
        </Cell>
        <Cell />
        <Cell center>
          <Link
            kind="primary"
            to="#Contact"
            onAnchorLinkClick={() => (window.location.href = button.url)}
          >
            <Button kind="primary" round>
              {button.text}
            </Button>
          </Link>
        </Cell>
      </Grid>
    </Section>
  );
};

export default ContactSection;
