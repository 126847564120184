import PropTypes from "prop-types";
import styled from "styled-components";
import { Cell as StyledCSSCell } from "styled-css-grid";

import { ScreenSize } from "@Components/core";

/**
 * Responsive version of styled-css-grid Cell.
 * Props:
 * heightLg - Height of row at large screen size.
 * heightMd - Height of row at medium screen size.
 * heightSm - Height of row at small screen size.
 * heightXs - Height of row at extra small screen size.
 * widthLg - Width of row at large screen size.
 * widthMd - Width of row at medium screen size.
 * widthSm - Width of row at small screen size.
 * widthXs - Width of row at extra small screen size.
 */
const Cell = styled(StyledCSSCell)`
  ${({
    height,
    heightLg,
    heightMd,
    heightSm,
    heightXs,
    width,
    widthLg,
    widthMd,
    widthSm,
    widthXs,
  }) => `
   @media only screen and (max-width: ${ScreenSize.lg}) {
     grid-column-end: span ${widthLg || width};
     grid-row-end: span ${heightLg || height};
   }
   @media only screen and (max-width: ${ScreenSize.md}) {
     grid-column-end: span ${widthMd || widthLg || width};
     grid-row-end: span ${heightMd || heightLg || height};
   }
   @media only screen and (max-width: ${ScreenSize.sm}) {
     grid-column-end: span ${widthSm || widthMd || widthLg || width};
     grid-row-end: span ${heightSm || heightMd || heightLg || height};
   }
   @media only screen and (max-width: ${ScreenSize.xs}) {
     grid-column-end: span ${widthXs || widthSm || widthMd || widthLg || width};
     grid-row-end: span ${
       heightXs || heightSm || heightMd || heightLg || height
     };
   }
 `}
`;

Cell.propTypes = {
  heightLg: PropTypes.number,
  heightMd: PropTypes.number,
  heightSm: PropTypes.number,
  heightXs: PropTypes.number,
  widthLg: PropTypes.number,
  widthMd: PropTypes.number,
  widthSm: PropTypes.number,
  widthXs: PropTypes.number,
};

export default Cell;
