import styled from "styled-components";

import {
  Color,
  FontSize,
  LineHeight,
  ScreenSize,
  Size,
} from "@Components/core";
import Link, { LinkProps } from "./Link";

const NavigationItem = styled(Link)<LinkProps>`
  color: ${({ kind }) =>
    kind === "primary" ? Color.navigation.primary : Color.navigation.secondary};
  margin: ${Size.navigation.item.mobile.margin.vertical}
    ${Size.navigation.item.mobile.margin.horizontal};

  &,
  & > * {
    font-size: ${FontSize.button};
    font-weight: bold;
    line-height: ${LineHeight.navigation};
  }

  @media only screen and (min-width: ${ScreenSize.sm}) {
    color: ${({ kind }) =>
      kind === "primary"
        ? Color.navigation.primary
        : Color.navigation.secondary};
    margin: ${Size.navigation.item.desktop.margin.top}
      ${Size.navigation.item.desktop.margin.right}
      ${Size.navigation.item.desktop.margin.bottom}
      ${Size.navigation.item.desktop.margin.left};
  }
`;

export default NavigationItem;
