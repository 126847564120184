import PropTypes from "prop-types";
import { Grid as StyledCSSGrid } from "styled-css-grid";
import styled from "styled-components";

import { ScreenSize } from "@Components/core";

const frGetter = (value: number | string) =>
  typeof value === "number" ? `repeat(${value}, 1fr)` : value;

/**
 * Responsive version of styled-css-grid Grid.
 * Props:
 * columnsLg - Number of columns at large screen size.
 * columnsMd - Number of columns at medium screen size.
 * columnsSm - Number of columns at small screen size.
 * columnsXs - Number of columns at extra small screen size.
 */
const Grid = styled(StyledCSSGrid)`
  @media only screen and (max-width: ${ScreenSize.lg}) {
    grid-column-gap: ${({ gap, gapLg = gap }) => gapLg};
    grid-template-columns: ${({ columns, columnsLg = columns }) =>
      frGetter(columnsLg)};
  }
  @media only screen and (max-width: ${ScreenSize.md}) {
    grid-column-gap: ${({ gapLg, gapMd = gapLg }) => gapMd};
    grid-template-columns: ${({ columnsLg, columnsMd = columnsLg }) =>
      frGetter(columnsMd)};
  }
  @media only screen and (max-width: ${ScreenSize.sm}) {
    grid-column-gap: ${({ gapMd, gapSm = gapMd }) => gapSm};
    grid-template-columns: ${({ columnsMd, columnsSm = columnsMd }) =>
      frGetter(columnsSm)};
  }
  @media only screen and (max-width: ${ScreenSize.xs}) {
    grid-column-gap: ${({ gapSm, gapXs = gapSm }) => gapXs};
    grid-template-columns: ${({ columnsSm, columnsXs = columnsSm }) =>
      frGetter(columnsXs)};
  }
  margin: 0 auto;
  max-width: ${({ fluid }) => (fluid && "none") || ScreenSize.lg};
`;

Grid.propTypes = {
  columnsLg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnsMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnsSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnsXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gapLg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gapMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gapSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gapXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fluid: PropTypes.bool,
};

export default Grid;
