import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { Cell, Grid, Section } from "@Components/blocks";
import { Color } from "@Components/core";
import { CenterSectionProps } from "@Models/section";

// Override some of the properties that `react-markdown` creates.
const MarkdownWrapper = styled.div`
  a {
    word-break: break-word;
  }
  h3 {
    color: ${Color.centerSection.h3.primary};
  }
`;

const CenterSection = ({ text }: CenterSectionProps) => {
  return (
    <Section align="center" background="white">
      <Grid
        columns={"2fr 3fr 2fr"}
        columnsLg={"1fr 2fr 1fr"}
        columnsMd={"0fr 1fr 0fr"}
      >
        <Cell></Cell>
        <Cell>
          <MarkdownWrapper>
            <ReactMarkdown>{text}</ReactMarkdown>
          </MarkdownWrapper>
        </Cell>
        <Cell></Cell>
      </Grid>
    </Section>
  );
};

export default CenterSection;
