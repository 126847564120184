import { Color, FontSize, LineHeight } from "@Components/core";

const CodeStyle = `
  pre code {
    display: block;
    overflow-x: scroll;
    padding: 20px;
    word-wrap: normal;
  }
  code {
    background-color: ${Color.code.bg};
    color: ${Color.code.text};
    font-size: ${FontSize.code};
    line-height: ${LineHeight.code};
    padding: 5px;
    word-wrap: normal;
  }
`;

export default CodeStyle;
