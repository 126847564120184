const FontSize = {
  b1: "20px", // Base reference of 1rem for large screens
  b2: "15px", // Base refrence of 1rem for small screens
  blockQuote: "0.8rem",
  button: "0.9rem",
  code: "0.8rem",
  h1: "2.4rem",
  h2: "1.6rem",
  h3: "1rem",
  h4: "0.7rem",
};

export default FontSize;
