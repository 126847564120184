import styled from "styled-components";

import { ScreenSize, Size } from "@Components/core";

interface Props {
  open: boolean;
}

const NavigationItemContainer = styled.div<Props>`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${Size.navigation.itemContainer.mobile.padding.vertical}
    ${Size.navigation.itemContainer.mobile.padding.vertical};

  & > * {
    display: ${({ open }) => (open ? "inherit" : "none")};
  }

  @media only screen and (min-width: ${ScreenSize.sm}) {
    flex-direction: row;
    padding: ${Size.navigation.itemContainer.desktop.padding.all};

    & > * {
      display: block;
    }
  }
`;

export default NavigationItemContainer;
