import { Color, FontSize, LineHeight, Size } from "@Components/core";

const HeadingStyle = `
  h1 {
    color: ${Color.heading.primary};
    font-size: ${FontSize.h1};
    line-height: ${LineHeight.h1};
    margin-block-end: ${Size.h1.margin.bottom};
    margin-block-start: ${Size.h1.margin.top};
    margin-inline-end: ${Size.h1.margin.right};
    margin-inline-start: ${Size.h1.margin.left};
  }
  h2 {
    color: ${Color.heading.primary};
    font-size: ${FontSize.h2};
    line-height: ${LineHeight.h2};
    margin-block-end: ${Size.h2.margin.bottom};
    margin-block-start: ${Size.h2.margin.top};
    margin-inline-end: ${Size.h2.margin.right};
    margin-inline-start: ${Size.h2.margin.left};
  }
  h3 {
    color: ${Color.heading.primary};
    font-size: ${FontSize.h3};
    line-height: ${LineHeight.h3};
    margin-block-end: ${Size.h3.margin.bottom};
    margin-block-start: ${Size.h3.margin.top};
    margin-inline-end: ${Size.h3.margin.right};
    margin-inline-start: ${Size.h3.margin.left};
  }
  h4 {
    color: ${Color.heading.primary};
    font-size: ${FontSize.h4};
    line-height: ${LineHeight.h4};
    margin-block-end: ${Size.h4.margin.bottom};
    margin-block-start: ${Size.h4.margin.top};
    margin-inline-end: ${Size.h4.margin.right};
    margin-inline-start: ${Size.h4.margin.left};
  }
`;

export default HeadingStyle;
