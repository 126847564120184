import React from "react";
import ReactMarkdown from "react-markdown";

import { Cell, Grid, Image, Section, SectionHeader } from "@Components/blocks";
import { ColumnProps, TwoColumnsSectionProps } from "@Models/section";
import { useImageQuery } from "@Hooks/image";

const Column = (props?: ColumnProps) => {
  if (props?.image && (props?.text || props?.title)) {
    return <FullColumn {...props} />;
  } else if (props?.image) {
    return <ImageColumn {...props} />;
  } else if (props?.text || props?.title) {
    return <TextColumn {...props} />;
  }
  return <Cell></Cell>;
};

const FullColumn = ({ image, text, title }: ColumnProps) => {
  const imageQuery = useImageQuery(image ?? "");
  return (
    <Cell>
      <Image borderRadius="md" src={imageQuery?.url} />
      <h2>{title}</h2>
      {text && <ReactMarkdown>{text}</ReactMarkdown>}
    </Cell>
  );
};

const ImageColumn = ({ image }: ColumnProps) => {
  const imageQuery = useImageQuery(image ?? "");
  return (
    <Cell>
      <Image
        alt={imageQuery?.description}
        borderRadius="md"
        src={imageQuery?.url}
        style={{ width: "100%" }}
      />
    </Cell>
  );
};

const TextColumn = ({ text, title }: ColumnProps) => {
  return (
    <Cell middle>
      <h2>{title}</h2>
      {text && <ReactMarkdown>{text}</ReactMarkdown>}
    </Cell>
  );
};

const TwoColumnsSection = ({ title, left, right }: TwoColumnsSectionProps) => {
  return (
    <Section align="left" background="white">
      <Grid columns={"1fr 0.1fr 1fr"} columnsSm={1}>
        <Cell width={3} widthSm={1}>
          <SectionHeader>{title}</SectionHeader>
        </Cell>
        <Column {...left} />
        <Column />
        <Column {...right} />
      </Grid>
    </Section>
  );
};

export default TwoColumnsSection;
