import styled from "styled-components";

import { Color, FontSize, Size } from "@Components/core";
import { KindProps } from "@Models/type";

interface Props extends KindProps {
  round?: boolean;
}

const Button = styled.button<Props>`
  background-color: ${({ kind }) => {
    switch (kind) {
      case "primary":
        return Color.button.bg.primary;
      case "secondary":
        return Color.button.bg.secondary;
      default:
        return Color.button.bg.primary;
    }
  }};
  border-radius: ${({ round }) => (round ? "9999px" : "0")};
  border: none;
  color: ${({ kind }) => {
    switch (kind) {
      case "primary":
        return Color.button.text.primary;
      case "secondary":
        return Color.button.text.secondary;
      default:
        return Color.button.text.primary;
    }
  }};
  font-size: ${FontSize.button};
  font-weight: bold;
  cursor: pointer;
  padding: ${Size.button.padding.vertical} ${Size.button.padding.horizontal};
`;

export default Button;
