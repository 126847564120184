import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { Color, Size } from "@Components/core";
import { DefaultProps } from "@Models/type";
import { GatsbyImage } from "./Image";

interface Props extends DefaultProps {
  alt?: string;
  image?: IGatsbyImageData;
}

const Container = styled.div`
  border: 1px solid ${Color.card.border};
  height: 100%;
`;

const ChildContainer = styled.div`
  padding: ${Size.xs};
`;

const Image = styled(GatsbyImage)`
  max-height: ${Size.card.height};
`;

const ImageContainer = styled.div`
  margin: ${Size.card.margin.top} ${Size.card.margin.right}
    ${Size.card.margin.bottom} ${Size.card.margin.left};
`;

const Card = ({ alt, children, image }: Props) => {
  return (
    <Container>
      {alt && image && (
        <ImageContainer>
          <Image alt={alt} fullSize image={image} />
        </ImageContainer>
      )}
      <ChildContainer>{children}</ChildContainer>
    </Container>
  );
};

export default Card;
