import React, { useState } from "react";
import styled from "styled-components";

import {
  Button,
  NavigationItem,
  NavigationItemContainer,
} from "@Components/blocks";
import { ScreenSize } from "@Components/core";
import { NavigationQuery } from "@Models/navigation";
import { KindProps } from "@Models/type";
import NavigationBar from "./NavigationBar";

const NavigationButton = styled(Button)`
  display: none;

  @media only screen and (min-width: ${ScreenSize.sm}) {
    display: inherit;
  }
`;

const NavigationItemTitle = styled.span<KindProps>`
  @media only screen and (min-width: ${ScreenSize.sm}) {
    display: ${({ kind }) => (kind === "primary" ? "none" : "inherit")};
  }
`;

const Navigation = ({ navigations, ...navigationQuery }: NavigationQuery) => {
  const [navigationBarOpen, setNavigationBarOpen] = useState(false);

  return (
    <NavigationBar onStateChange={setNavigationBarOpen} {...navigationQuery}>
      <NavigationItemContainer open={navigationBarOpen}>
        {navigations.map((item, index) => (
          <NavigationItem
            kind={item.kind}
            title={item.name}
            to={item.url}
            key={index}
          >
            {item.kind === "primary" && (
              <NavigationButton kind={item.kind} round>
                {item.name}
              </NavigationButton>
            )}
            <NavigationItemTitle kind={item.kind}>
              {item.name}
            </NavigationItemTitle>
          </NavigationItem>
        ))}
      </NavigationItemContainer>
    </NavigationBar>
  );
};

export default Navigation;
