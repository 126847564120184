import { AnchorLink, AnchorLinkProps } from "gatsby-plugin-anchor-links";
import styled from "styled-components";

import { Color } from "@Components/core";
import { KindProps } from "@Models/type";

type Props = AnchorLinkProps & KindProps;

const Link = styled(AnchorLink)<Props>`
  color: ${({ kind }) => {
    switch (kind) {
      case "primary":
        return Color.link.primary;
      case "secondary":
        return Color.link.secondary;
      default:
        return Color.link.primary;
    }
  }};
  text-decoration: none;
`;

export { Props as LinkProps };
export default Link;
