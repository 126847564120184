import React from "react";

import { Cell, Grid, Image, Section, SectionHeader } from "@Components/blocks";
import { useImageQuery } from "@Hooks/image";
import { TestimonialSectionProps } from "@Models/section";

const TestimonialSection = ({
  image,
  name,
  position,
  quote,
  quoteImage,
  title,
}: TestimonialSectionProps) => {
  const imageQuery = useImageQuery(image);
  const quoteImageQuery = useImageQuery(quoteImage ?? "");
  return (
    <Section align="left" background="blue">
      <Grid columns={"0.5fr 0.1fr 1fr"} columnsMd={1} columnsSm={1}>
        <Cell width={3} widthSm={1}>
          <SectionHeader align="center">{title}</SectionHeader>
        </Cell>
        <Cell width={3} widthSm={1} />
        <Cell center>
          <Image round testimonial src={imageQuery?.url} />
        </Cell>
        {quoteImageQuery && (
          <Cell>
            <Image src={quoteImageQuery?.url} />
          </Cell>
        )}
        <Cell middle>
          <p>{quote}</p>
          <p>
            <b>{name}</b>
            <br />
            {position}
          </p>
        </Cell>
      </Grid>
    </Section>
  );
};

export default TestimonialSection;
