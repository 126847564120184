import React from "react";
import styled from "styled-components";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

import { Color } from "@Components/core";

interface Props extends Omit<FontAwesomeIconProps, "icon"> {
  highlight: boolean;
}

// https://github.com/styled-components/styled-components/issues/1198
interface IconProps {
  $highlight: boolean;
}

const Icon = styled(FontAwesomeIcon)<IconProps>`
  color: ${({ $highlight }) =>
    $highlight ? Color.hamburger.highlight : Color.hamburger.regular};
  cursor: pointer;
`;

const Hamburger = ({ highlight, ...fontAwesomeIconProps }: Props) => {
  return (
    <Icon icon={faBars} $highlight={highlight} {...fontAwesomeIconProps} />
  );
};

export default Hamburger;
