import { graphql, useStaticQuery } from "gatsby";

interface ImageQuery {
  description: string;
  height: number;
  url: string;
  width: number;
}

const useImageQuery = (id: string): ImageQuery | undefined => {
  const query = useStaticQuery(graphql`
    query ImageQuery {
      allContentfulAsset {
        nodes {
          contentful_id
          description
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
        }
      }
    }
  `);
  const nodes = query.allContentfulAsset.nodes;
  return nodes
    .filter((node: any) => node.contentful_id === id)
    .map((node: any) => {
      return {
        description: node.description,
        height: node.file.details.image.height,
        url: node.file.url,
        width: node.file.details.image.width,
      };
    })[0];
};

export default useImageQuery;
