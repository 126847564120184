const LineHeight = {
  b: "1.4rem",
  blockQuote: "1.6rem",
  code: "1rem",
  h1: "3.2rem",
  h2: "2rem",
  h3: "1.4rem",
  h4: "1.1rem",
  navigation: "1.15rem",
};

export default LineHeight;
