import React from "react";
import { GatsbyImage as GImage, GatsbyImageProps } from "gatsby-plugin-image";
import styled, { css } from "styled-components";

import { Size } from "@Components/core";

interface Props {
  borderRadius?: "sm" | "md";
  fullSize?: boolean;
  round?: boolean;
}

interface ImageProps extends Props {
  logo?: boolean;
  testimonial?: boolean;
}

const ImageStyle = css<Props>`
  border-radius: ${({ borderRadius, round }) => {
    if (round) {
      return "50%";
    }
    switch (borderRadius) {
      case "sm":
        return Size.img.borderRadius.sm;
      case "md":
        return Size.img.borderRadius.md;
      default:
        0;
    }
  }};
  max-width: 100%;
  object-fit: contain;

  ${({ fullSize }) =>
    !!fullSize &&
    `
    height: 100%;
    width: 100%;
  `}
`;

const Image = styled.img<ImageProps>`
  ${({ logo }) =>
    !!logo &&
    `
    height: ${Size.hero.companies.logo.size};
    max-width: 200px;
  `}
  ${({ testimonial }) =>
    !!testimonial &&
    `
    height: ${Size.testimonial.img.size};
  `}
`;

const GatsbyImageWrapper = styled.div<Props>`
  ${ImageStyle}
  overflow: hidden;
`;

const GatsbyImage = ({
  borderRadius,
  fullSize,
  ...gatsbyImageProps
}: Props & GatsbyImageProps) => {
  return (
    <GatsbyImageWrapper borderRadius={borderRadius} fullSize={fullSize}>
      <GImage {...gatsbyImageProps} />
    </GatsbyImageWrapper>
  );
};

export { GatsbyImage, Image };
