import { Color, FontSize, LineHeight, Size } from "@Components/core";

const BlockQuoteStyle = `
  blockQuote {
    border-left: 3px solid ${Color.blockQuote.border};
    color: ${Color.blockQuote.text};
    font-size: ${FontSize.blockQuote};
    font-style: italic;
    line-height: ${LineHeight.blockQuote};
    margin-block-end: ${Size.blockQuote.margin.bottom};
    margin: auto;
    padding: ${Size.blockQuote.padding.vertical} ${Size.blockQuote.padding.horizontal};
    position: relative;
    width: 100%;
  }
  blockQuote p {
    margin-block-end: 0px;
  }
`;

export default BlockQuoteStyle;
