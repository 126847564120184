import styled from "styled-components";

import { Color, ScreenSize, Size } from "@Components/core";

interface Props {
  align: "center" | "left" | "right";
  background: "blue" | "white";
}

const Section = styled.div<Props>`
  background-color: ${({ background }) => {
    switch (background) {
      case "blue":
        return Color.page.bg.secondary;
      case "white":
        return Color.page.bg.primary;
      default:
        return Color.page.bg.primary;
    }
  }};
  color: ${Color.page.text.primary};
  padding: ${Size.section.desktop.padding.vertical}
    ${Size.section.desktop.padding.horizontal};
  text-align: ${({ align }) => align};

  @media only screen and (max-width: ${ScreenSize.sm}) {
    padding: ${Size.section.mobile.padding.vertical}
      ${Size.section.mobile.padding.horizontal};
  }
`;

export default Section;
