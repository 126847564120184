/**
 * Most common sizes used:
 * xs  - 16px
 * sm  - 48px
 * md  - 64px
 * lg  - 80px
 * xl  - 100px
 * xxl - 160px
 */
const AppSize = {
  xs: "0.8rem",
  sm: "2.4rem",
  md: "3.2rem",
  lg: "4rem",
  xl: "5rem",
  xxl: "8rem",
};

/**
 * Creates proper map based on the number of params equivalent to CSS shorthands.
 * @param one If this is the only param, then return a value for all 4 sides
 * @param two If one, and two, return values for horizontal and vertical sides
 * @param three If one, two, and three, return left, up, right, then bottom as 0
 * @param four If one, two, three, and four, return all 4 sides individually
 */
const getSpacingMap = (
  one: number | string,
  two?: number | string,
  three?: number | string,
  four?: number | string
) => {
  const hasTwo = two !== undefined;
  const hasThree = three !== undefined;
  const hasFour = four !== undefined;

  if (hasTwo && hasThree && hasFour) {
    // 4 params are defined
    return {
      bottom: three.toString(),
      left: four.toString(),
      right: two.toString(),
      top: one.toString(),
    };
  } else if (hasTwo && hasThree) {
    // 3 params are defined
    return {
      bottom: three.toString(),
      horizontal: two.toString(),
      top: one.toString(),
    };
  } else if (hasTwo) {
    // 2 params are defined
    return {
      horizontal: two.toString(),
      vertical: one.toString(),
    };
  }
  // 1 param is defined
  return {
    all: one.toString(),
  };
};

const Size = {
  ...AppSize,
  blockQuote: {
    margin: getSpacingMap(0, 0, AppSize.sm, 0),
    padding: getSpacingMap("10px", "30px"),
  },
  blog: {
    image: { margin: getSpacingMap(0, 0, AppSize.sm, 0) },
  },
  button: { padding: getSpacingMap(AppSize.xs, `calc(2 * ${AppSize.xs})`) },
  card: {
    height: "400px",
    margin: getSpacingMap(0, 0, AppSize.xs, 0),
  },
  footer: { padding: getSpacingMap(0, AppSize.xl, "40px", AppSize.xl) },
  h1: { margin: getSpacingMap(0, 0, AppSize.xs, 0) },
  h2: { margin: getSpacingMap(0, 0, AppSize.xs, 0) },
  h3: { margin: getSpacingMap(0, 0, AppSize.xs, 0) },
  h4: { margin: getSpacingMap(0, 0, 0, 0) },
  hero: {
    button: {
      margin: getSpacingMap(AppSize.sm, 0, 0, 0),
    },
    companies: {
      title: {
        margin: getSpacingMap(AppSize.xxl, 0, 0, 0),
      },
      logo: {
        size: "70px",
        margin: getSpacingMap(`calc(2 * ${AppSize.xs})`, 0, 0, 0),
      },
    },
  },
  hr: { margin: getSpacingMap(0, 0, AppSize.sm, 0) },
  img: {
    borderRadius: {
      sm: "10px",
      md: "30px",
    },
  },
  navigation: {
    bar: {
      desktop: { padding: getSpacingMap(AppSize.sm, AppSize.xl, 0) },
      mobile: { padding: getSpacingMap(AppSize.sm, AppSize.sm, 0) },
    },
    item: {
      desktop: { margin: getSpacingMap(0, 0, 0, AppSize.sm) },
      mobile: { margin: getSpacingMap(`calc(${AppSize.xs} / 2)`, 0) },
    },
    itemContainer: {
      desktop: { padding: getSpacingMap(0) },
      mobile: { padding: getSpacingMap(AppSize.xs, 0) },
    },
  },
  p: { margin: getSpacingMap(0, 0, AppSize.sm, 0) },
  section: {
    desktop: { padding: getSpacingMap(AppSize.md, AppSize.xl) },
    mobile: { padding: getSpacingMap(AppSize.md, AppSize.sm) },
  },
  testimonial: {
    img: {
      size: "250px",
    },
  },
};

export default Size;
