import styled from "styled-components";

interface Props {
  align?: "center" | "left" | "right";
}

const SectionHeader = styled.h2<Props>`
  & > p {
    margin-block-end: 0;
  }
  ${({ align }) => align && `text-align: ${align};`}
`;

export default SectionHeader;
