import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/700.css";
import { createGlobalStyle } from "styled-components";

import { FontSize, ScreenSize } from "@Components/core";
import BlockQuoteStyle from "./BlockQuoteStyle";
import BodyStyle from "./BodyStyle";
import CodeStyle from "./CodeStyle";
import HRStyle from "./HRStyle";
import HeadingStyle from "./HeadingStyle";

const GlobalStyle = createGlobalStyle`
  ${BlockQuoteStyle}
  ${BodyStyle}
  ${CodeStyle}
  ${HeadingStyle}
  ${HRStyle}

  html {
    font-family: -apple-system, DM Sans, sans-serif, serif;
    font-size: ${FontSize.b1};
    @media only screen and (max-width: ${ScreenSize.sm}) {
      font-size: ${FontSize.b2};
    }
  }
`;

export default GlobalStyle;
