import { Color, LineHeight, Size } from "@Components/core";

const BodyStyle = `
  a {
    color: ${Color.link.primary};
    text-decoration: none;
  }
  body {
    background: ${Color.page.bg.primary};
    color: ${Color.page.text.primary};
    font-size: 1rem;
    line-height: ${LineHeight.b};
    margin: 0;
  }
  p {
    margin-block-end: ${Size.p.margin.bottom};
    margin-block-start: ${Size.p.margin.top};
    margin-inline-end: ${Size.p.margin.right};
    margin-inline-start: ${Size.p.margin.left};
  }
  strong {
    color: ${Color.strong.primary};
  }
`;

export default BodyStyle;
