import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Hamburger, Image, Link } from "@Components/blocks";
import { Size, ScreenSize } from "@Components/core";
import { NavigationQuery } from "@Models/navigation";
import { DefaultProps } from "@Models/type";

interface Props extends DefaultProps, Omit<NavigationQuery, "navigations"> {
  onStateChange?: (open: boolean) => void;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledHamburger = styled(Hamburger)`
  @media only screen and (min-width: ${ScreenSize.sm}) {
    display: none;
  }
`;

const StyledNavigationBar = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${ScreenSize.lg};
  padding: ${Size.navigation.bar.mobile.padding.top}
    ${Size.navigation.bar.mobile.padding.horizontal}
    ${Size.navigation.bar.mobile.padding.bottom};

  @media only screen and (min-width: ${ScreenSize.sm}) {
    flex-direction: row;
    justify-content: space-between;
    padding: ${Size.navigation.bar.desktop.padding.top}
      ${Size.navigation.bar.desktop.padding.horizontal}
      ${Size.navigation.bar.desktop.padding.bottom};
  }
`;

const NavigationBar = ({ badge, children, onStateChange }: Props) => {
  const [navigationBarOpen, setNavigationBarOpen] = useState(false);
  const handleClick = () => setNavigationBarOpen(!navigationBarOpen);

  useEffect(() => {
    onStateChange && onStateChange(navigationBarOpen);
  }, [navigationBarOpen]);

  return (
    <StyledNavigationBar>
      <Container>
        <Link kind="primary" title="Home" to="/">
          <Image alt={badge.description} src={badge.url} />
        </Link>
        <StyledHamburger highlight={navigationBarOpen} onClick={handleClick} />
      </Container>
      {children}
    </StyledNavigationBar>
  );
};

export default NavigationBar;
