import React from "react";
import styled from "styled-components";

import { Color, ScreenSize, Size } from "@Components/core";
import { Cell, Grid, Section } from "@Components/blocks";

interface Props {
  background: "blue" | "white";
}

interface TextProps {
  align: "center" | "left" | "right";
}

const FooterSection = styled(Section)`
  padding: ${Size.footer.padding.top} ${Size.footer.padding.right}
    ${Size.footer.padding.bottom} ${Size.footer.padding.left};
`;

const Text = styled.h4<TextProps>`
  color: ${Color.footer.text.primary};
  text-align: ${({ align }) => align};

  @media only screen and (max-width: ${ScreenSize.sm}) {
    text-align: center;
  }
`;

const Footer = ({ background }: Props) => {
  return (
    <FooterSection align="left" background={background}>
      <Grid columns={2} columnsSm={1}>
        <Cell>
          <Text align="left">From Toronto, CA</Text>
        </Cell>
        <Cell>
          <Text align="right">&#169; AppLit {new Date().getFullYear()}</Text>
        </Cell>
      </Grid>
    </FooterSection>
  );
};

export default Footer;
