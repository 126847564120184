import React from "react";

import { SEO } from "@Components/blocks";
import { GlobalStyle } from "@Components/styles";
import { DefaultProps } from "@Models/type";

const Page = ({ children }: DefaultProps) => {
  return (
    <main>
      <SEO />
      <GlobalStyle />
      {children}
    </main>
  );
};

export default Page;
