const AppColor = {
  blueLightest: "#F5F8FD",
  blue: "#55677C",
  blueDark: "#090E36",
  greyLight: "#DDDDDD",
  orangeDark: "#EC4529",
  transparent: "transparent",
  white: "#FFFFFF",
};

const BlogColor = {
  black: "#000000",
  offWhite: "#EEEEEE",
  darkGrey: "#292929",
};

const Color = {
  blockQuote: {
    border: BlogColor.black,
    text: AppColor.blue,
    quote: AppColor.orangeDark,
  },
  button: {
    bg: {
      primary: AppColor.orangeDark,
      secondary: AppColor.transparent,
    },
    text: {
      primary: AppColor.white,
      secondary: AppColor.blue,
    },
  },
  card: {
    border: AppColor.greyLight,
  },
  centerSection: {
    h3: {
      primary: AppColor.blue,
    },
  },
  code: {
    bg: BlogColor.offWhite,
    text: BlogColor.darkGrey,
  },
  footer: {
    text: {
      primary: AppColor.blue,
    },
  },
  hamburger: {
    highlight: AppColor.orangeDark,
    regular: AppColor.blueDark,
  },
  heading: {
    primary: AppColor.blueDark,
  },
  heroSection: {
    strong: {
      primary: AppColor.orangeDark,
    },
  },
  hr: {
    primary: AppColor.blue,
  },
  link: {
    primary: AppColor.orangeDark,
    secondary: AppColor.blue,
  },
  navigation: {
    primary: AppColor.orangeDark,
    secondary: AppColor.blueDark,
  },
  page: {
    bg: {
      primary: AppColor.white,
      secondary: AppColor.blueLightest,
    },
    text: {
      primary: AppColor.blue,
    },
  },
  strong: {
    primary: AppColor.orangeDark,
  },
};

export default Color;
