import { Color, Size } from "@Components/core";

const HRStyle = `
  hr {
    background-color: ${Color.hr.primary};
    border-radius: 50%;
    border: 0;
    content: "";
    display: block;
    height: 7px;
    margin-block-end: ${Size.hr.margin.bottom};
    overflow: visible;
    position: relative;
    width: 7px;
  }
  hr:before {
    background-color: ${Color.hr.primary};
    border-radius: 50%;
    content: "";
    display: block;
    height: 7px;
    left: -32px;
    position: absolute;
    width: 7px;
    -webkit-animation: dot-move-left 1s ease-out forwards;
            animation: dot-move-left 1s ease-out forwards;
  }
  hr:after {
    background-color: ${Color.hr.primary};
    border-radius: 50%;
    content: "";
    display: block;
    height: 7px;
    left: 32px;
    position: absolute;
    width: 7px;
    -webkit-animation: dot-move-right 1s ease-out forwards;
            animation: dot-move-right 1s ease-out forwards;
  }
`;

export default HRStyle;
