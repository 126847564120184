import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { Button, Cell, Grid, Image, Link, Section } from "@Components/blocks";
import { Size } from "@Components/core";
import { useImageQuery } from "@Hooks/image";
import { CarouselProps, HeroSectionProps } from "@Models/section";

// Override some of the properties that `react-markdown` creates.
const StyledH1 = styled.h1`
  & > p {
    margin-block-end: 0;
  }
`;

const StyledH3 = styled.h3`
  & > p {
    margin-block-end: 0;
  }
  text-align: center;
`;

const CompanyCarousel = (companies: CarouselProps) => {
  const numCompanyGridColumns = Math.min(companies.imageIds.length, 3);
  const imageQueries = companies.imageIds.map((imageId) =>
    useImageQuery(imageId)
  );

  return (
    <>
      <StyledH3 style={{ marginTop: Size.hero.companies.title.margin.top }}>
        {companies.title}
      </StyledH3>
      <Grid
        columns={numCompanyGridColumns}
        style={{ marginTop: Size.hero.companies.logo.margin.top }}
      >
        {imageQueries.map((imageQuery) => (
          <Cell center key={imageQuery?.description} middle>
            <Image
              src={imageQuery?.url}
              height={imageQuery?.height}
              width={imageQuery?.width}
              style={{ margin: "0 auto" }}
              logo
            />
          </Cell>
        ))}
      </Grid>
    </>
  );
};

const HeroSection = ({ button, companies, largeText }: HeroSectionProps) => {
  return (
    <Section align="left" background="white">
      <Grid columns={3} columnsMd={2} columnsSm={1}>
        <Cell width={2} widthSm={1}>
          <StyledH1>
            <ReactMarkdown>{largeText}</ReactMarkdown>
          </StyledH1>
          {button && (
            <Link
              kind="primary"
              to="#"
              onAnchorLinkClick={() => (window.location.href = button.url)}
            >
              <Button
                kind="primary"
                round
                style={{ marginTop: Size.hero.button.margin.top }}
              >
                {button.text}
              </Button>
            </Link>
          )}
        </Cell>
      </Grid>
      {companies && <CompanyCarousel {...companies} />}
    </Section>
  );
};

export default HeroSection;
