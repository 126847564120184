import React, { useState } from "react";
import styled from "styled-components";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import { Size } from "@Components/core";

interface Props {
  getContent: (page: number) => React.ReactNode;
  totalPages: number;
}

interface IconProps extends FontAwesomeIconProps {
  disabled: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${Size.xs};
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
  cursor: ${({ disabled }) => (disabled ? "inherit" : "pointer")};
  margin-left: ${Size.xs};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Paginator = ({ getContent, totalPages }: Props) => {
  const [page, setPage] = useState(0);

  return (
    <div>
      <ButtonContainer>
        {totalPages > 1 && (
          <>
            <Icon
              disabled={page === 0}
              icon={faChevronCircleLeft}
              onClick={() => setPage(Math.max(page - 1, 0))}
            />
            <Icon
              disabled={page === totalPages - 1}
              icon={faChevronCircleRight}
              onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
            />
          </>
        )}
      </ButtonContainer>
      {getContent(page)}
    </div>
  );
};

export default Paginator;
